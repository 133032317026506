import EventEmitter from 'events';
import AnalyticEvent from './analytic-event';
import clone from 'lodash/clone';
import bind from 'lodash/bind';
import merge from 'lodash/merge';

export const DEFAULT_VERSION = 1;
export const DEBUG = 'debuggah';

class AnalyticsCollector extends EventEmitter {
  constructor() {
    super();

    this.AnalyticEvent = AnalyticEvent;
    this.url = '/xapi/ac/pub/1.0/event';
    this.bulkUrl = `${this.url}/bulk`;
    this.appName = undefined;
    this.campaignId = undefined;
    this.crossDomain = false;
    this.loginSessionId = undefined;
    this.mfgId = undefined;
    this.rootEventId = '';
    this.timeout = 10000;
    this.userUuid = undefined;
  }

  init(config = {}, ...args) { // url, appName, gaId, mfgId, campaignId, AnalyticEvent, ga
    const parseParams = (config, args) => {
      if (typeof config === 'object') {
        // This is the new syntax so just return it outright
        return config;
      }

      // Destructure the old syntax and reconstitue it as an object for the new syntax
      const [appName, gaId, mfgId, campaignId, AnalyticEvent] = args;
      return { AnalyticEvent, appName, campaignId, mfgId, url: config };
    };

    const {
      AnalyticEvent = this.AnalyticEvent,
      appName = this.appName,
      campaignId = this.campaignId,
      loginSessionId = this.loginSessionId,
      mfgId = this.mfgId,
      url,
      userUuid = this.userUuid,
    } = parseParams(config, args);

    if(url) {
      this.url = `${url}/1.0/event`;
      this.bulkUrl = `${this.url}/bulk`;
      this.crossDomain = window.location.host !== this._extractHostFromUrlBase(url);
    }

    this.AnalyticEvent = AnalyticEvent;
    this.appName = appName;
    this.campaignId = campaignId;
    this.loginSessionId = loginSessionId;
    this.mfgId = mfgId;
    this.userUuid = userUuid;

    this.emit('did-init');
    return this;
  }

  emit(type, ...args) {
    super.emit(type, ...args);

    if (document?.dispatchEvent && CustomEvent && (type === 'did-send' || type === 'will-send')) {
      // Publish an event on the document so there can be a centralized place to listen for events.
      args?.forEach((detail) => {
        document.dispatchEvent(new CustomEvent(`exp:analytics:${type}`, { detail }));
      });
    }
  }

  /**
   * Send event to the analytics collector.
   *
   * @param action
   * @param data
   * @param version
   * @param consumers
   * @param parent
   * @returns AnalyticEvent
   */
  sendEvent(action, data = {}, version, consumers, parent) {
    const event = this.createAnalyticsEvent(action, data, version, consumers, parent);
    return this.doSend(event);
  }

  /**
   * Send bulk event to the analytics collector.  This function does not return any data, unlike what its sibling
   * function #sendEvent returns.
   *
   * @param action
   * @param bulkData
   * @param version
   * @param consumers
   * @param parent
   */
  sendBulkEvents(action, bulkData = [], version, consumers, parent) {
    const events = this.createBulkAnalyticsData(action, bulkData, version, consumers, parent);
    return this.doSendBulk(events);
  }

  /**
   * Creates the analytics data.
   *
   * @param action
   * @param data
   * @param consumers
   * @param parent
   * @param version
   * @returns AnalyticEvent
   */
  createAnalyticsEvent(action, data, version, consumers, parent) {
    return new this.AnalyticEvent(action, data, this.appName, version ?? DEFAULT_VERSION, this.guid(), consumers ?? [], parent ?? this.rootEventId).toJSON();
  }

  /**
   * Creates the bulk analytics data and returns an Array. If the incoming bulkData does not happen to be and
   * object Array, then that data is wrapped in an array and returned.
   *
   * @param action
   * @param bulkData
   * @param version
   * @param consumers
   * @param parent
   * @returns {Array.<AnalyticEvent>}
   */
  createBulkAnalyticsData(action, bulkData, version, consumers, parent) {
    if(Array.isArray(bulkData)) {
      return bulkData.map(data => this.createAnalyticsEvent(action, data, version, consumers, parent));
    }

    // Not an array so, make it one after creating the event
    // eslint-disable-next-line no-console
    console.warn('You\'re attempting to send a single event through a bulk channel. We\'ve handled it, but you should check your configuration.');
    return [].concat(this.createAnalyticsEvent(action, bulkData, version, consumers, parent));
  }

  /**
   * Send the analytics data.
   * @param data
   * @returns {*}
   */
  doSend(data) {
    data.data = data.data || {};
    data.url = window.location.href;
    data.userAgent = navigator.userAgent;
    data.referrer = document.referrer;
    data.mfgId = data.mfgId || this.mfgId;
    data.campaignId = data.campaignId || this.campaignId;
    data.loginSessionId = data.loginSessionId || this.loginSessionId;
    data.userUuid = data.userUuid || this.userUuid;
    data.data = this._emitAppendedData(data.data);

    this.emit('will-send', clone(data));
    const promise = this.doPostRequest(data, this.url);

    return merge(promise, data);
  }

  /**
   * Send the bulk analytics data. If the incoming data is not in the form of an array then it is
   * sent through the non-bulk channel and the expected response is returned.  Bulk data submission
   * will not return a response.
   *
   * @param bulkData
   * @returns {*}
   */
  doSendBulk(bulkData) {
    if(!Array.isArray(bulkData)) {
      // not an array, so just pass it along to doSend()
      // eslint-disable-next-line no-console
      console.warn('You\'re attempting to send a single event through a bulk channel. We\'ve handled it,but you should check your configuration. Sending as single message');
      return this.doSend(bulkData);
    }

    bulkData = bulkData.map((data) => {
      data.campaignId = data.campaignId || this.campaignId;
      data.data = data.data || {};
      data.loginSessionId = data.loginSessionId || this.loginSessionId;
      data.mfgId = data.mfgId || this.mfgId;
      data.referrer = document.referrer;
      data.url = window.location.href;
      data.userAgent = navigator.userAgent;
      data.userUuid = data.userUuid || this.userUuid;
      return data;
    });

    bulkData.forEach((data) => {
      data.data = this._emitAppendedData(data.data);
      this.emit('will-send', clone(data));
    });

    this.doPostRequest(bulkData, this.bulkUrl);
  }

  /**
   * Send a PAGEVIEW event.
   * @param data
   * @returns {string}
   */
  pv(data) {
    this.rootEventId = this.sendEvent('PAGEVIEW', data).eventId;
    return this.rootEventId;
  }

  guid() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  doPostRequest(data, url) {
    const timeout = this.timeout;
    const emit = bind(this.emit, this);
    const body = this._normalizeBody(data);

    return new Promise((resolve, reject) => {
      // Try the navigator.sendBeacon if it exists
      try {
        if (navigator.sendBeacon && navigator.sendBeacon(url, body)) {
          emit('did-send', clone(data));
          resolve(clone(data));
          return;
        }
      } catch (e) {
        // Do nothing
      }

      // Queue up a timer to automatically reject the promise if it takes too long
      let timer = setTimeout(() => {
        const error = new Error(`Exceeded timeout of {timeout}`);
        emit('error:request-timeout', error);
        reject(error);
      }, timeout);

      // Construct the XHR request however the browser supports it
      let xhr;
      if (window.XMLHttpRequest) {
        xhr = new window.XMLHttpRequest();
      } else if (!this.crossDomain && window.ActiveXObject) {
        xhr = new window.ActiveXObject('MSXML2.XMLHTTP.3.0');
      } else {
        throw new Error('Cannot make request due to the browser being old');
      }

      // Send the XHR request
      xhr.open('POST', url);

      if (this.crossDomain) {
        xhr.withCredentials = true;
      } else {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      }

      xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
      xhr.send(body);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          clearTimeout(timer);

          if (xhr.status < 300) {
            try {
              let response = '';
              if (xhr.responseText !== '') {
                response = JSON.parse(xhr.responseText);
              }
              emit('did-send', clone(response));
              resolve(response);
            } catch (e) {
              emit('error:invalid-response', e);
              reject(e);
            }
          } else {
            const error = new Error(xhr.responseText || xhr.statusText);
            emit('error:invalid-request', error);
            reject(error);
          }
        }
      };
    });
  }

  /**
   * Will extract the host value from the configured urlBase,
   * defaulting to the window.location.host.
   * @param url {string}
   * @private
   * @return {string}
   */
  _extractHostFromUrlBase(url) {
    // Short-cut by letting the DOM do the work
    const a = (document && document.createElement('a')) || {};
    a.href = url;

    // If the URL is relative IE has troubles w/ the host value
    if (!a.host) a.host = a.href;

    return a.host || window.location.host;
  }

  /**
   * Will properly escape if it contains any newline or carriage returns.
   * @param {string} body - The body of the response
   * @private
   * @return {string} A normalized body
   */
  _normalizeBody(body) {
    return JSON.stringify(body, (k, v) => {
      if (typeof v !== 'string') return v;
      return v.replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');
    });
  }

  /**
   * Allows for additional data to be appended without it coming from the origin event.
   * @param {Object} data - The data key coming from the published event.
   * @private
   * @return {Object} The merged data between data from the origin request and the listener.
   */
  _emitAppendedData(data) {
    const appendedData = {};
    this.emit('append-data', appendedData);
    return merge({}, data, appendedData);
  }
}

export default AnalyticsCollector;
