class AnalyticEvent {
  constructor(action, data, appName, version, eventId, consumers, parentEventId) {
    this.action = action;
    this.data = data;
    this.appName = appName;
    this.version = version;
    this.eventId = eventId;
    this.parentEventId = parentEventId;
    this.userAgent = '';
    this.url = '';
    this.mfgId = data.mfgId || data.orgId || '';
    this.campaignId = data.campaignId || '';
    this.referrer = '';

    if(consumers) {
      if(Array.isArray(consumers)) {
        this.consumers = consumers;
      } else {
        this.consumers = [consumers];
      }
    }
  }

  toJSON() {
    const hash = {};

    Object.keys(this).forEach((key) => {
      hash[key] = this[key];
    });

    return hash;
  }
}

export default AnalyticEvent;
